<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Concept</h1>
      <p>
        Our project <em>Granja Caimito</em> aims at creating food for humans in a sustainable
        way that mimics a natural ecosystem. Some people call that approach <em>regenerative agriculture</em>.</p>
      <p>
        We are not about extracting from the ecosystem but instead we aim to generate abundance
        so that we can take away the surplus for human consumption without forcing the whole system into decline.
      </p>

      <h2 class="underdashed text-xl">Creating an Ecosystem Farm</h2>
      <p>
        Our land is 45 ha located in the Valle de los Pedroches in Andalusia, Spain. It is situated in an old
        agroforestry system called <em>Dehesa</em> in Spanish. The landscape is dominated by oak trees and not much is
        left from the other vegetation that once existed. The soil is mostly damaged and has very few organic material
        in it. The following picture is from summer 2022:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-06-08_21-17-36.jpg">
        <img src="../../assets/images/2022-06-08_21-17-36.jpg">
      </picture>

      <p>
        An ecosystem is complex and there is not a clear path to achieve anything. Nature is additive and
        for everything there are multiple ways and multiple actors. Nature is resilient because one could remove
        one element and the ecosystem keeps functioning. However, removing too much will likely lead to its decline.
      </p>
      <p>
        To us that means we work simultaneously on multiple topics and make decisions based on knowledge
        but to a large extend based on observation. What works elsewhere is an inspiration to us but we
        think we should not copy but instead find our way of applying a principle that has worked in a
        similar situation as ours.</p>

      <h2 class="underdashed text-xl">Agriculture</h2>
      <p>
        As the soil is depleted and <router-link :to="{ name: 'desertification' }">
          desertification</router-link> can be seen clearly there is no way to plant or harvest a crop at this time.
        That has been done in the past and led to the current situation. We rather use the existing resources including
        the water to restore soil and forest so that later on we will be able to do agroforestry do obtain a produce.
      </p>

      <h2 class="underdashed text-xl">Animal Impact</h2>
      <p>
        We do keep a larger number of animals as they perform a vital function in our restoration effort. For example,
        not only do their excrements add nutrients to the soil but also their hooves open up the crust to let water
        infiltrate and the uprooting of pigs also helps the soil. We do spent a lot of time on managing the animal
        impact properly.
      </p>

      <YouTubeLink />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import YouTubeLink from '@/components/YouTubeLink.vue';

export default {
  name: "ConceptEN",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
